.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkboxContainer input {
  margin-right: 10px;
}

.checkboxContainer label,input{
  display: inline-block;
  vertical-align: middle;
} 

.checkboxContainer input[type="checkbox"] {
  width: 15px;
  height: 15px; 
}

.checkboxDiv {
  display: flex;
  margin-bottom: 10px;
}

.checkboxButtons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
}

.inputField {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.passField {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 5px;
  width: 80%;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
}

.passDiv {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  margin-bottom: 10px;
}

.copyPassBtn {
  margin-left: 10px;
    &:hover {
      filter: drop-shadow(0 0 10px rgb(10, 10, 10));
    }
}
