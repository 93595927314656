.options {
    margin-top: 30px;
    scroll-margin-top: 80px;
}

.panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.option {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 35px;
    margin-bottom: 18px;
    scroll-margin-top: 80px;

    .chooseBtn {
        margin-right: 10px;
    }

    .btnWrapper {
        transition: all .3s;
        width: 32px;
        height: 32px;
        border-radius: 50%;

        &:hover {
            background: #ECEEF2;
        }
    }
}

.info {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.optionWrapper {
    border-bottom: 1px solid #BEC4D1;

    .input {
        width: 100%;
        height: 30px;
        resize: none;
        min-height: 30px;
        padding: 0 10px 10px 0;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-thumb:hover {
            display: none;
        }
    }

    &.emptyVariant {
        border-bottom: 1px solid #C50000;


        textarea::placeholder {
            color: #C50000;
            opacity: 1;
        }
    }
}

.setCorrectBtn {
    fill: #2F2F2F;

    &.error {
        fill: #C50000;
    }
}