.scroll {
    width: 150px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 95000;
    bottom: 10px;
    left: 85vw;

    .text {
        font-weight: 300;
    }
}