.textElement {
    font-weight: 500;
    color: #1D7EC3;
    text-decoration: underline;
    white-space: nowrap;
    display: flex;

    .text {
        font-weight: 500;
        color: #1D7EC3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 450px;
    }
}

.audio {
    width: 100%;
    height: 40px;
}

.images {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, 350px);

    .image {
        width: 350px;
        height: 260px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.videos {
    .video {
        width: 80%;
    }
}