.maxScore {
    display: flex;
    align-items: center;

    &.error {
        .initialValue {
            border: 1px solid #C50000;
        }
    }
}

.input,
.initialValue {
    width: 26px;
    height: 26px;
    background: #F1F3F4;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    text-align: center;
    margin-left: 7px;
}

.initialValue {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BEC4D1;
}