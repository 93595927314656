.content {
    user-select: none;
}

.title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;
}

.itemWrapper {
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    margin-bottom: 30px;
}

.text,
.textOption {
    font-size: 16px;
    line-height: 19px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.textOption {
    font-size: 14px;
}

.input {
    width: 100%;
    padding-bottom: 3px;
    margin-top: 30px;
    display: flex;
    border-bottom: 1px solid #BEC4D1;

    textarea {
        width: 100%;
        resize: none;
        padding-right: 5px;

        &::placeholder {
            font-weight: 300;
        }
    }

    &.isOnFocus {
        border-bottom: 1px solid #491B94;
    }
}

.options {
    margin-top: 30px;
}

.option {
    display: flex;
    align-items: flex-start;

    &Text {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 15px;
    }

    &:not(:last-child) {
        margin-bottom: 18px;
    }

    &Content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.banner {
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.multipleCorrectFlag {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 15px;

    span {
        font-weight: 500;
        font-size: 12px;
    }
}

.optionContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 15px;
    width: 100%;
}

.requeredFlag {
    color: #C50000;
    padding-left: 3px;
}

.buttons {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.sendButton {
    color: #FFFFFF;
    background: #6E49A8;
    border: 1px solid #491B94;
    border-radius: 5px;
    width: auto;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 30px;
    margin: 5px 30px 10px;

    &:hover {
        box-shadow: 0px 4px 15px #BEC4D1;
        background: #491B94;
    }
}

.button {
    color: #6E49A8;
    background: #FFFFFF;
    border: 1px solid #491B94;
    border-radius: 5px;
    width: auto;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 35px;
    margin: 5px 30px 10px;

    &:hover {
        box-shadow: 0px 4px 15px  #BEC4D1;
        background:   #6E49A8;
        color: white;
    }
}

.categoryTitle {
    font-size: 32px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    word-break: break-all;
    word-wrap: break-word;
    margin: 20px;
    line-height: 38px;
}

.categoryContainer {
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    background:  #6E49A8;
    color: #FFFFFF;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 5px;
}