.title {
    display: flex;
    border-radius: 5px;
    margin-bottom: 12px;
    padding: 5px 5px 5px 0;

    .input {
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 36px;
        padding-right: 10px;
    }

    .confirmIcon {
        fill: #acadaf;
        margin-top: 1px;

        &.active {
            fill: #6E49A8;
        }
    }

    &.titleEditing {
        padding: 5px;
        background: #F1F3F4;
        border: 1px solid #BEC4D1;
    }
}

.tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 195px;
    width: 305px;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    padding: 30px 25px 30px 30px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0px 4px 15px #DCDCDC;
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .info {
        .counts {
            color: #757575;
        }
    }
}

.list {
    padding: 0 12px 0 7px;
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto 30px;
    margin-bottom: 5px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    .mainInfo {
        display: grid;
        align-items: center;
        grid-template-columns: 50px auto 140px 170px 150px;
        padding: 13px 0px;
    }

    &.isSelected {
        background: #ECEEF2;
    }

    .counts,
    .createdOn,
    .editedOn {
        color: #757575;
    }

    .title {
        margin-bottom: 0;
        margin-right: 20px;
    }

    .icon {
        width: 24px;
    }

    .selectIcon {
        cursor: pointer;
        padding: 5px;
    }
}

.list:hover,
.tile:hover {
    box-shadow: 0px 4px 15px #DCDCDC;
}