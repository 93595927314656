.element {
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    padding: 5px 24px 5px 0;
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    max-height: 30px;
    white-space: nowrap;

    &:hover {
        text-decoration-line: underline;
    }

    &.active {
        color: #757575;
    }

    &:not(:last-child)::after {
        position: absolute;
        content: url('../../../../images/path-arrow.svg');
        width: 8px;
        height: 12px;
        top: 6px;
        right: 7px;
    }
}