.inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #757575;
    height: 48px;
    background: #F1F3F4;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    padding: 0 14px;

    &.number {
        padding: 0 0 0 13px;
        width: 50px;

        .input {
            font-size: 24px;
            text-align: center;
        }
    }
}

.input {
    height: 100%;
    width: 100%;

    &.link {

        &:focus,
        &:focus {
            color: #1D7EC3;
            text-decoration: underline;

            &::placeholder {
                text-decoration: none;
            }
        }
    }

    &::placeholder {
        color: #757575;
        text-decoration: none;
        text-decoration-color: transparent;
    }
}

.clearButton {
    cursor: pointer;
}