.form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 116px;
    justify-content: space-between;
}

.localizationSwitcher {
    display: flex;
    position: relative;
    left: 47%;
    bottom: 47%;
}