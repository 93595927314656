.form {
    max-width: 330px;
    width: 100%;
}

.logo {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .subText {
        margin-top: 14px;
        text-align: right;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        user-select: none;
    }
}

.loginError {
    margin-top: 8px;
    text-align: center;
    color: #FF4747;
}