.dropdown {
    z-index: 10000;
    height: 48px;
    max-width: 380px;
    width: 100%;
    position: relative;
    font-size: 14px;
    user-select: none;


    &.active {
        z-index: 100;
    }
}

.dropdownBtn {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #FFFFFF;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: none;

    span {
        display: flex;
        align-items: center;
    }

    &Icon,
    .selectedIcon {
        margin-right: 10px;
    }

    &Icon.open {
        transform: rotate(180deg);
    }
}

.dropdownContentWrapper {
    position: absolute;
    padding: 6px;
    left: 0;
    top: 56px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    z-index: 1000;
    border: 1px solid #BEC4D1;

    .dropdownContent {
        overflow-y: scroll;
        max-height: 250px;

        .item {
            cursor: pointer;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 7px 13px 9px;

            &:hover {
                .itemInfo {
                    font-weight: 500;
                }
            }
        }

        .item:first-child {
            padding: 7px 7px 13px 9px;
            border-radius: 5px 5px 0 0;
        }

        .item:last-child {
            padding: 13px 7px 7px 9px;
            border-radius: 0 0 5px 5px;
        }
    }
}

.itemInfo {
    display: flex;

    .nestedOptionIcon {
        margin-right: 10px;
    }
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: #BEC4D1;
    border-radius: 5px;
}