.popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #2f2f2f8c;
    cursor: pointer;
    user-select: none;
    z-index: 20000000;
}

.content {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px #656565;
    border-radius: 5px;
    padding: 20px;

    .title {
        font-weight: 500;
        margin: 10px 0 20px 0;
        text-align: center;
    }

    .closeIcon {
        display: block;
        margin-left: auto;
        cursor: pointer;
    }
}