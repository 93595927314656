.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.panelContent {
    display: flex;
    align-items: center;


    .saveFolderTiteBtn {
        width: 48px;
        height: 48px;
        margin-left: 12px;
        background: #BEC4D1;
        border-radius: 5px;

        &.isAvailable {
            background: #6E49A8;
        }
    }
}

.buttonTutors {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 31px;
    border: 1px solid black;
    margin-right: 12px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        background: #ECEEF2;
        cursor: pointer;
    }
}
