.head {
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 85%;
  padding: 30px;
  margin-top: 20px;
  background: #FFFFFF;
  cursor: pointer;
  border: 1px solid #BEC4D1;
  border-radius: 5px;

  &:hover {
    position: relative;
    box-shadow: 0px 4px 15px #BEC4D1;
    transition: all 0.4s;

    .deleteButton {
      display: block;
    }
  
    &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        background: #491B94;
        top: 0;
        left: 0;
        border-radius: 5px 0 0 5px;
    }
  }
}

.dragIcon {
  position: absolute;
  width: 94%;
  text-align: center;
  top: 5px;
  user-select: none;
  cursor: move;
}

.title {
  position: relative;
  resize: none;
  background: transparent;
  margin-bottom: 32px;

  &Input {
      width: 96%;
      height: 48px;
      padding-bottom: 10px;
      border-bottom: 1px solid #BEC4D1;
      font-size: 32px;
      line-height: 38px;

      &::-webkit-scrollbar {
        display: none;
      }
    
      &::-webkit-scrollbar-track {
          display: none;
      }
    
      &::-webkit-scrollbar-thumb {
          display: none;
      }
    
      &::-webkit-scrollbar-thumb:hover {
          display: none;
      }

      &:hover {
          border-bottom: 1px solid #491B94;
      }

      &::placeholder {
          font-weight: 300;
          font-size: 32px;
          line-height: 38px;
          color: #565656;
      }
  }
}

.deleteButton {
  display: none;
  position: absolute;
  top: -17px;
  right: -14px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1000;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.385));
  }
}

.shuffleQuestionCheckBox {
  display: flex;
  align-items: center;
}

.shuffleQuestionCheckBox input {
  margin-right: 10px;
}

.shuffleQuestionCheckBox label {
  font-size: 18px;
  font-weight: 400;
}

.shuffleQuestionCheckBox label, input {
  display: inline-block;
  vertical-align: middle;
}

.shuffleQuestionCheckBox input[type="checkbox"] {
  width: 20px;
  height: 20px; 
  border:  1px solid black;
}