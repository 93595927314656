.dropdown {
  z-index: 10000;
  height: 48px;
  max-width: 380px;
  width: 100%;
  position: relative;
  font-size: 14px;
  user-select: none;


  &.active {
    z-index: 100;
  }

  &.question {
    .dropdownContent {
      box-shadow: 0px 4px 15px #BEC4D1;
    }
  }

  &.common {
    .dropdownContent {
      box-shadow: none;
      border: 1px solid #BEC4D1;

      .item {
        &:hover {
          .itemInfo {
            font-weight: 500;
          }
        }
      }
    }
  }
}



.dropdownBtn {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #BEC4D1;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: none;

  &.error {
    border: 1px solid #C50000;
  }

  span {
    display: flex;
    align-items: center;
  }

  &Icon,
  .selectedIcon {
    margin-right: 10px;
  }

  &Icon.open {
    transform: rotate(180deg);
  }
}

.dropdownContent {
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  z-index: 1000;

  .item {
    cursor: pointer;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 7px 13px 15px;

    &Info {
      display: flex;
      align-items: center;
    }

    &Info img {
      margin-right: 10px;
    }

    &:hover {
      .radioIcon {
        background: #F1F3F4;
        border-radius: 50%;
      }
    }
  }

  .item:first-child {
    border-radius: 5px 5px 0 0;
  }

  .item:last-child {
    border-radius: 0 0 5px 5px;
  }
}


.radioIcon {
  transition: all 0.3s;
  padding: 6px;
}