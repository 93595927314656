.error {
    position: absolute;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    color: #C50000;

    &.hidden {
        display: none;
    }
}