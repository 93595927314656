@font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: local("Roboto-Thin.ttf"),
        url("./Roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: local("Roboto-Regular.ttf"),
        url("./Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: local("Roboto-Medium.ttf"),
        url("./Roboto/Roboto-Medium.ttf") format("truetype");
}