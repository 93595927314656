.assignTutorPopupContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }
}

.tutorAssinged {
    margin-bottom: 15px;
}

.element {
    display: flex;
    align-items: center;

    img {
        margin-right: 7px;
        height: 12px;
        width: 13px;
    }
}