.contentWrapper {
    padding: 22px 15px;
    background-color: #fff;
    border: 1px solid #BEC4D1;
    border-radius: 5px 0 5px 5px;
}

.content {
    height: 52vh;
    overflow-x: hidden;
}

.head * {
    font-weight: 500;
}

.head div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card {
    height: 40px;
    transition: all .3s;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 3px;
    border-radius: 5px;

    &.isSelected,
    &:hover {
        background-color: #ECEEF2;
    }
}

.cardGridContainer,
.cardGridContainerTutor {
    display: grid;
    align-items: center;
    grid-template-columns: 40px auto 30px;
    padding: 0 8px
}

.cardGridContainerTutor {
    grid-template-columns: 40px auto;
}

.cardInfo {
    padding: 12px 0px;

    div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.cardGrid,
.tutorAccessCardGrid {
    display: grid;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    grid-template-columns: 0.7fr 0.8fr 0.7fr 0.5fr 0.4fr 0.8fr;
}

.iconWrapper {
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    padding: 9px 0;
}

.email,
.tutor {
    overflow-y: hidden;
    text-overflow: clip;
    margin-right: 15px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.selectedPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    margin-bottom: 22px;

    &Count,
    &Item {
        padding: 8px 12px;
        font-weight: 500;
        color: #491B94;
        user-select: none;
    }

    &Item {
        display: flex;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            background-color: #ECEEF2;
            border-radius: 5px;
        }
    }

    &Icon {
        margin-left: 7px;
        fill: #491B94;
    }
}