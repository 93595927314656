.tileView {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 305px);
    justify-content: center;
    position: relative;
}

.listView {
    position: relative;

    &Head {
        display: grid;
        grid-template-columns: 50px 50px auto 140px 170px 150px 30px;
        padding: 0 12px 0 7px;
        height: 55px;
        align-items: center;

        div {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.emptyMessage {
    position: absolute;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    margin-top: 15px;
    user-select: none;
}

.selectAllIcon {
    cursor: pointer;
    padding: 5px;
}

.selectedMenu {
    display: flex;
    cursor: pointer;

    div {
        white-space: nowrap;
    }

    div:not(:last-child) {
        margin-right: 25px;
    }

    &Option {
        color: #491B94;

        &:hover {
            font-weight: 600;
        }
    }
}

.panelContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 90vh;
    bottom: 0;
    left: 0;
    right: 280px;
}