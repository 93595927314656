.content {
    max-width: 900px;
    margin: 50px auto;
    width: 100%;
}

.head {
    display: flex;
    margin-bottom: 28px;

    .title {
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
}

.cardWrapper {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #BEC4D1;
    margin-bottom: 30px;
}

.mainInfo {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.infoBlock {
    display: flex;

    &Titles {
        & div {
            font-weight: 500;
        }

        margin-right: 30px;
    }
}

.mainInfoTitle+.mainInfoTitle,
.mainInfoItem+.mainInfoItem {
    margin-top: 14px;
}

.categoryTitle {
    font-size: 32px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    word-break: break-all;
    word-wrap: break-word;
    margin: 20px;
    line-height: 38px;
}

.categoryContainer {
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    background:  #6E49A8;
    color: #FFFFFF;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 5px;
}