.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    flex-direction: column;
    max-width: 260px;
    width: 100%;
    cursor: pointer;
    .subText {
        margin-top: 8px;
        text-align: right;
        font-size: 16px;
        line-height: 19px;
        user-select: none;
    }
}

.logout {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 40px;

    span {
        margin-right: 10px;
    }
}