.prompt {
    position: relative;

    &Content {
        position: absolute;
        display: none;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px;
        padding: 5px;
        font-size: 10px;
        line-height: 12px;
        transition: all 0.3s;
        user-select: none;
    }

    &Arrow {
        position: absolute;
        width: 18px;
        bottom: -6px;
    }

    &:hover {
        .promptContent {
            display: inline-block;
        }
    }
}