.wrapper {
    min-height: 100vh;
    padding: 30px 80px 80px;
    overflow-x: hidden;
    background: #E9ECF3;

    &::-webkit-scrollbar {
        display: none;
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 65vh;
    user-select: none;

    .largeText {
        font-weight: 200;
        font-size: 40px;
        line-height: 56px;
    }

    .mediumText {
        font-size: 24px;
        line-height: 28px;
    }

    .smallText {
        font-size: 14px;
        margin-top: 10px;
    }
}