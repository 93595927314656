.head {
    width: 100%;
    max-height: 190px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 0 0 5px 5px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        position: relative;
        box-shadow: 0px 4px 15px #BEC4D1;
        transition: all 0.4s;

        &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 100%;
            background: #491B94;
            top: 0;
            left: 0;
            border-radius: 0 0 0 5px;
        }
    }
}

.title {
    position: relative;
    margin-bottom: 32px;

    &Input {
        width: 100%;
        height: 48px;
        padding-bottom: 10px;
        border-bottom: 1px solid #BEC4D1;
        font-size: 32px;
        line-height: 38px;

        &:hover {
            border-bottom: 1px solid #491B94;
        }

        &::placeholder {
            font-weight: 300;
            font-size: 32px;
            line-height: 38px;
            color: #565656;
        }

        &.error {
            border-bottom: 1px solid #C50000;
        }
    }
}

.questionsCount span {
    font-weight: 500;
    margin-left: 3px;
}

.attachedBanner {
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.testShuffleQuestionCheckBox input {
    margin-right: 10px;
}

.testShuffleQuestionCheckBox label, input {
    display: inline-block;
    vertical-align: middle;
}

.testShuffleQuestionCheckBox label {
    font-size: 15px;
    font-weight: 400;
}

.testShuffleQuestionCheckBox input[type="checkbox"] {
    width: 20px;
    height: 20px; 
    border:  1px solid black;
}
