.head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 28px;
}

.title {
    font-weight: 500;
}

.inputs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 11px;
}

.hours,
.minutes,
.inputsWrapper {
    display: flex;
}

.inputsWrapper {
    width: 105px;
    justify-content: space-between;
}

.hours,
.minutes {
    flex-direction: column;
}

.dots {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40px;

    .dot {
        background: #2F2F2F;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }
}

.text {
    font-weight: 300;
    font-size: 12px;
    margin-top: 9px;
    text-align: center;
}

.btns {
    display: flex;
    justify-content: space-around;
    margin-top: 14px;
}