.element {
    display: flex;
    align-items: center;
}

.removeButton {
    margin-right: 11px;
    cursor: pointer;
}

.text {
    color: #1D7EC3;
    font-weight: 500;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 350px;
}

a {
    color: #1D7EC3;
    font-weight: 500;
}