.title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;
}

.content {
    max-width: 850px;
    width: 100%;
    margin: 40px auto 0;
    position: relative;
}

.itemWrapper {
    max-width: 850px;
    width: 100%;
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    margin-bottom: 30px;
    overflow-x: hidden;
}

.banner {
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.localizationSwitcher {
    display: flex;
    position: relative;
    float: right;
    bottom: 75px;
}

.buttons {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.sendButton {
    color: #FFFFFF;
    background: #6E49A8;
    border: 1px solid #491B94;
    border-radius: 5px;
    width: auto;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 30px;
    margin: 5px 30px 10px;

    &:hover {
        box-shadow: 0px 4px 15px #BEC4D1;
        background: #491B94;
    }
}

.button {
    color: #6E49A8;
    background: #FFFFFF;
    border: 1px solid #491B94;
    border-radius: 5px;
    width: auto;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 35px;
    margin: 5px 30px 10px;

    &:hover {
        box-shadow: 0px 4px 15px  #BEC4D1;
        background:   #6E49A8;
        color: white;
    }
}

.categoryTitle {
    font-size: 32px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    word-break: break-all;
    word-wrap: break-word;
    margin: 20px;
    line-height: 38px;
}

.categoryContainer {
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    background:  #6E49A8;
    color: #FFFFFF;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 5px;
}
