.tile {
    height: 195px;
    width: 305px;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0px 4px 15px #DCDCDC;
    }

    .photo {
        height: 125px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .info {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #BEC4D1;
        padding: 0 20px;

        .title {
            font-size: 16px;
            line-height: 19px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 36px;
        }
    }
}

.list {
    padding: 0 12px 0 7px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 50px auto 30px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    .mainInfo {
        display: grid;
        grid-template-columns: 50px auto 140px 170px 150px;
        align-items: center;
        padding: 13px 0px;
    }

    &.isSelected {
        background: #ECEEF2;
    }

    .counts,
    .createdOn,
    .editedOn {
        color: #757575;
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 24px;
        line-height: 23px;
        white-space: nowrap;
        padding-right: 30px;
    }

    .icon {
        width: 18px;
        height: 24px;
    }

    .selectIcon {
        cursor: pointer;
        padding: 5px;
    }
}

.list:hover,
.tile:hover {
    box-shadow: 0px 4px 15px #DCDCDC;
}