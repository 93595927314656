.options {
    margin-top: 30px;
}

.option {
    display: grid;
    grid-template-columns: 35px auto;
    cursor: pointer;
    transition: all 0.45s;
    margin-bottom: 13px;
    text-overflow: ellipsis;

    &:hover {
        .iconWrapper {
            background: #ECEEF2;
        }
    }
}

.optionContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
}

.iconWrapper {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.45s;
}

.text {
    padding-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.multipleCorrectFlag {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 15px;

    span {
        font-weight: 500;
        font-size: 12px;
    }
}