.info {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.input {
    width: 100%;
    height: 32px;
    resize: none;
    min-height: 32px;
    padding: 0 10px 10px 0;
    border-bottom: 1px solid #BEC4D1;

    &.full {
        border-bottom: 1px solid #491B94;
    }

    &.error {
        border-bottom: 1px solid #C50000;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;

    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        display: none;
    }
}