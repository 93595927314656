.button {
    height: 48px;
    width: 100%;
    background: linear-gradient(88.79deg, #CD62FF 0%, #3FD4A2 100%);
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    transition: all 1s;

    &:hover {
        background: linear-gradient(88.79deg, #CD62FF 0%, #3FD4A2 70%);
    }
}