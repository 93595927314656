.card {
    position: relative;
    width: 100%;
    min-height: 185px;
    height: 100%;
    padding: 30px;
    margin-top: 20px;
    border-radius: 5px;
    background: #FFFFFF;
    cursor: pointer;
    border: 1px solid #BEC4D1;

    &.active {
        position: relative;
        box-shadow: 0px 4px 15px #BEC4D1;
        transition: all 0.4s;

        &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 100%;
            background: #491B94;
            top: 0;
            left: 0;
            border-radius: 5px 0 0 5px;
        }
    }

    &:hover {
        position: relative;
        box-shadow: 0px 4px 15px #BEC4D1;
        transition: all 0.4s;

        .deleteButton {
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 100%;
            background: #491B94;
            top: 0;
            left: 0;
            border-radius: 5px 0 0 5px;
        }
    }
}

.deleteButton {
    display: none;
    position: absolute;
    top: -17px;
    right: -14px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1000;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.385));
    }
}

.openQuestionPanel {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.dragIcon {
    position: absolute;
    width: 94%;
    text-align: center;
    top: 5px;
    user-select: none;
    cursor: move;
}