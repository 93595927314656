.wrapper {
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.head {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    width: 100%;
    margin-right: 30px;
    flex-shrink: 0;

    &Info {
        display: flex;
        flex-direction: column;
        background: #F1F3F4;
        border: 1px solid #BEC4D1;
        border-radius: 5px 5px 0px 0px;
        padding: 15px;

        &.error {
            border: 1px solid #C50000;
        }

        .info {
            .attached {
                margin-top: 15px;
            }
        }
    }
}

.input {
    width: 100%;
    resize: none;
    background: transparent;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        display: none;
    }
}

.attachments {
    display: flex;

    .addButton {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: center;
        border: 1px solid #BEC4D1;
        background: #FFFFFF;
        transition: all 0.3s;
        border-top: none;
        min-width: 189px;
        border-radius: 0 0 0 5px;

        &:hover {
            background: #F1F3F4;
        }

        &:last-child {
            border-radius: 0 0 5px 0;
            border-left: none;
        }
    }
}

.selectPanel {
    max-width: 380px;
    width: 100%;

    .switcher {
        margin-top: 17px;
    }
}