*,
body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2F2F2F;
    scroll-margin-top: 30px;
}

input,
button,
textarea {
    outline: none;
    border: none;
    font-family: inherit;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin: 0;
    background: none;
    color: #2F2F2F;
}

a {
    text-decoration: none;
    color: inherit;
}

svg {
    fill: #2F2F2F;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #a2a1a1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #8e8d8d;
}

.swal2-container {
    z-index: 900000 !important;
}