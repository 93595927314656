.card {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  &:hover {
    background-color: #6E49A8;
    color: white;
    cursor: pointer;
  }
}