.logo {
    width: 80px;
}

.photoTemplate {
    background: #E9ECF3;
    padding: 10px;
    height: 123px;
}

.defaultPhoto {
    width: 200px;
    height: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.info {
    margin: 0 auto;
    background-color: #fff;
    width: 215px;
    padding: 5px;
    text-align: center;
    margin-top: 6px;
    border-radius: 3px;
}

.title {
    font-size: 11px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    max-width: 130px;
    margin: 0 auto;
    max-height: 18px;
}