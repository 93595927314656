.wrapper {
    max-width: 950px;
    width: 100%;
    margin: 15px auto 0 auto;
}

.head {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}

.tab {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 17px;
    cursor: pointer;
}

.tab.active {
    font-weight: 500;
    position: relative;

    &::before {
        content: '';
        left: 0;
        bottom: 0;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #491B94;
        animation: animation 1.5s forwards;
    }
}

@keyframes animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}