.panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 15px;
}

.view {
    background-color: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
        background-color: #ECEEF2;
    }

    .icon {
        height: 32px;
        width: 32px;
        transition: all .3s;
        padding: 7px;
        cursor: pointer;
        user-select: none;
    }

    .icon:not(:first-child) {
        margin-left: 24px;
    }
}

.path {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}