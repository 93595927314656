.notificationWrapper {
  position: relative;
  justify-content: center;

  .notification {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 0px #808080;
    border-radius: 5px;
    padding: 10px;
    font-size: 11px;
    line-height: 13px;
    user-select: none;
    white-space: nowrap;
    z-index: 10;
  }
}