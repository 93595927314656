.addQuestion {
    position: relative;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    text-align: center;
    align-items: center;
    background: #E9ECF3;
    padding: 12px 0px 0px 0px;
    z-index: 90000;
    display: block;
    justify-content: center;
    img {
        cursor: pointer;
    }
}

.addQuestionBtn {
    align-self: center;
}

// .items {
//     position: fixed;
//     z-index: 90000;
//     display: flex;
//     background-color: rgb(233, 236, 243);
//     padding: 0px 0px 0px;
//     flex-direction: row;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     justify-content: center;
// }

.items {
    position: relative;
    z-index: 90000;
    display: flex;
    background-color: rgb(233, 236, 243);
    padding: 0px 0px 0px;
    flex-direction: row;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
}

.itemBtn {
    padding: 10px 31px 10px;
    margin: 5px 30px 10px;
    font-size: 16px;
    width: 200px;
    font-weight: 500;
    color: #FFFFFF;
    background: #6E49A8;
    border: 1px solid #491B94;
    border-radius: 5px;

    &:hover {
        box-shadow: 0px 4px 15px #BEC4D1;
        background: #491B94;
    }
}
