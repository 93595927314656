.card {
  position: relative;
}

.expandCollapseBtn {
  display: block;
  position: absolute;
  z-index: 1;
  transition: all 0.3s;
  top: 40px;
  right: 20px;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.385));
  }
}