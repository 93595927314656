.wrapper {
    position: relative;
    z-index: 12;
    cursor: pointer;
    z-index: 91000;

    .menuIconWrapper {
        background-color: none;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.3s;

        &.active,
        &:hover {
            background-color: #ECEEF2;
        }
    }

    .menuIcon {
        display: inline-block;
    }

    .menu {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px #BEC4D1;
        border-radius: 5px;

        &Item {
            display: flex;
            align-items: center;
            height: 48px;
            white-space: nowrap;
            padding: 12px;
            transition: all .3s;

            img {
                margin-right: 15px;
                color: aqua;
            }

            &:hover {
                background: #E9ECF3;
            }
        }
    }
}

.menuIcon {
    fill: #2F2F2F;

    &.active {
        fill: #491B94;
    }
}