.title {
    margin-bottom: 10px;
    color: #fff;
}

.input {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    background: #2F2F2F;
    border-radius: 5px;
    padding: 0 12px;

    input {
        color: #fff;
        background: none;
        height: 100%;
        width: 100%;
    }

    .eye {
        cursor: pointer;
    }

    &.error {
        border: 1px solid #FF4747;
    }

    .errorText {
        position: absolute;
        bottom: -20px;
        left: 0;
        font-size: 12px;
        line-height: 14px;
        color: #FF4747;
    }
}

input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white !important;
}