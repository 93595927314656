.createButton {
  display: flex;
  justify-content: flex-end;
}

.deleteButton {
  display: flex;
  justify-content: center;
}

.selectedPanelIcon {
  &:hover {
    filter: drop-shadow(0 0 10px rgb(10, 10, 10));
  }
}

.contentTable {
  border-collapse: collapse;
  margin: 25px auto;
  width: 55%;
  border-radius: 10px 10px 0 0;
  background-color: white;
  box-shadow: 0 0 0 1px gray;
  overflow: hidden;
}

.contentTable thead tr {
  background-color: #6E49A8;
  text-align: left;
}

.contentTable thead th {
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.contentTable tr, th, td {
  padding: 12px 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
// сделать цвет разный через раз, посмотреть на меню (там серый цвет вроде норм)
.contentTable td {
  // border-bottom: 1px solid #ddd;
  // border-right: 1px solid #ddd;
}

.columnOne {
  width: 300px;
  max-width: 300px;
}

.columnTwo {
  width: 550px;
  max-width: 550px;
}

.contentTable tbody tr:nth-of-type(even) {
  // background-color: #ECEEF2;
  background-color: #e2d3f8
}

.clickableTestId {
  color: blue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: darkblue;
  }
}

.emptyTable {
  display: flex;
  justify-content: center;
}