.answer {
    max-width: 850px;
    width: 100%;
    padding: 30px 30px 35px 30px;
    background: #FFFFFF;
    border: 1px solid #BEC4D1;
    border-radius: 5px;
    margin-bottom: 30px;
    user-select: none;

    &.error {
        border: 1px solid #FF4747;
    }
}

.requiredField {
    display: flex;
    margin-top: 30px;
    color: #d93025;
    font-weight: 500;
}

.requeredFlag {
    color: #C50000;
    padding-left: 3px;
}

.attachments {
    margin-bottom: 25px;
}

.text {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
}