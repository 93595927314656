$active-bg-color: black;

.localizationSwitcher {
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    transition: all .2s;
  
    &.left {
      border: 1px solid black;
      border-radius: 5px 0 0 5px;
    }
  
    &.right {
      border: 1px solid black;
      border-radius: 0px 5px 5px 0px;
    }

    &.active {
      background-color: $active-bg-color;
      color: white;
    }

    &.inactive {
      background-color: transparent;
      color: black;
    }
}