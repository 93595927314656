.wrapper {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #0D0E12;
    color: #FFFFFF;
}

.gradient {
    position: absolute;
    user-select: none;
    pointer-events: none;
    opacity: 0;
    z-index: 1;

    &:first-child {
        top: 0;
        right: 0;
        transform: translate(580px, -680px);
        animation: animation 2.8s forwards;
    }

    &:last-child {
        bottom: 0;
        left: 0;
        transform: translate(-550px, 510px);
        animation: animation 2.8s forwards;
    }
}

.children {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.localizationSwitcher {
    display: flex;
    position: relative;
    float: right;
    top: 30px;
    right: 65px;
    z-index: 2;
}