.wrapper {
    position: relative;
}

.popup {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px #DCDCDC;
    border-radius: 5px;
    padding: 16px;
    z-index: 50000;
}