.switcher {
    position: relative;
    display: flex;
    align-items: center;
    width: 30px;
    height: 19px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s;


    &.notactive {
        background: #bec4d1;

        &::after {
            position: absolute;
            content: "";
            background: #fff;
            width: 16px;
            height: 15px;
            border-radius: 50%;
            left: 2px;
            top: 2px;
        }
    }

    &.active {
        background: #491B94;

        &::after {
            position: absolute;
            content: "";
            background: #fff;
            width: 16px;
            height: 15px;
            border-radius: 50%;
            right: 2px;
            top: 2px;
        }
    }
}

.title {
    padding-left: 38px;
    white-space: nowrap;
}