.timer {
    box-shadow: 0px 4px 15px 0px #BEC4D1;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 170px;
    position: absolute;
    right: -185px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;

    .title {
        font-size: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        img {
            margin-left: 9px;
        }
    }

    .divider {
        margin: 20px 0;
        height: 1px;
        width: 100%;
        background-color: #BEC4D1;
    }

    .time {
        font-weight: 200;
        font-size: 24px;
    }

    &.timeIsOver {
        background: #f5dfdf;
    }

    .timeDots {
        padding: 0 3px;
    }
}