.button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    span {
        font-weight: 500;
        color: #2F2F2F;
        margin-left: 10px;
    }
}