.panel {
    width: 100%;
    padding: 10px;
    border-radius: 0 0 5px 5px;
    border: 1px solid #BEC4D1;
    border-top: none;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}