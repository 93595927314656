.card {
    &.border {
        border-top: 1px solid #BEC4D1;
        padding-top: 30px;
    }
}

.card:not(:last-child) {
    margin-bottom: 30px;
}

.questionText {
    font-weight: 500;
    margin-bottom: 14px;
}

.optionItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    div {
        padding-top: 2px;
    }
}

.optionContent {
    margin: 0 10px;

    .text {
        margin-bottom: 10px;
    }
}

.answer {
    margin-top: 30px;
}

.correctIcon {
    margin-left: auto;
}

.score {
    position: relative;
    width: 100%;
    text-align: right;
    margin-top: 5px;

    .input {
        border-radius: 5px;
        background-color: #F1F3F4;
        border: 1px solid #BEC4D1;
        width: 30px;
        text-align: center;
        height: 28px;

        &.isImmutable {
            color: #BEC4D1;
        }
    }
}