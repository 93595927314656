.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 31px;
    transition: all .3s;
    font-size: 16px;

    .icon {
        margin-right: 10px;
    }

    &.medium {
        height: 42px;
    }

    &.large {
        height: 48px;
    }

    &.main {
        background: #491B94;
        color: #FFFFFF;
        font-weight: 500;

        &:hover {
            box-shadow: 0px 4px 15px #BEC4D1;
        }

        &.disabled {
            background: #D2CDE5;
            color: #757575;

            &:hover {
                box-shadow: none;
            }
        }
    }

    &.seconadry {
        &:hover {
            background: #ECEEF2;
        }

        &.disabled {
            color: #757575;

            &:hover {
                background: none;
            }
        }
    }

    &.outlined {
        border: 1px solid #491B94;

        &.disabled {
            color: #757575;
            border: 1px solid #d2cde5;
        }
        &:hover {
            box-shadow: 0px 4px 15px #BEC4D1;
        }
    }
}